<template>
  <div class="wrapper wrapper--home">
    <div v-if="tourActive" class="wrapper-tour wrapper-tour--home" ref="content">
      <iframe :src="frameUrl" :style="styleIframeHeight"></iframe>
    </div>
    <div v-else>
      <div class="background-image">
        <img :src="`${publicPath}/img/content/zanier-360-bg-home.jpg`" title="Zanier 360°" alt="Zanier 360°">
      </div>
      <div class="wrapper-content wrapper-content--home" ref="content" >
        <div class="wrapper__inner">
          <div class="row row--intro">
            <div class="col-6">
              <header>
                <h1 class="content-title">We focus on<br/>what we are<br/>living for.</h1>
                <p class="content-subtitle">#mymountainpassion</p>
              </header>
            </div>
            <div class="col-6 col--logo">
              <figure class="logo--intro">
                <img :src="`${publicPath}/img/content/zanier-logo.svg`" alt="Zanier 360°" title="Zanier 360°" />
              </figure>
              <p>360°</p>
            </div>
          </div>
          <div class="row row--start-links">
            <a href="#" @click="startTour('de', $event)" class="btn btn-primary">Start german tour</a>
            <a href="#" @click="startTour('en', $event)" class="btn btn-primary">Start english tour</a>
          </div>
          <div class="row row--footer">
            <div class="col-6">
              <figure>
                <img :src="`${publicPath}/img/content/use-mouse.svg`" alt="use mouse to look around" title="use mouse to look around" />
              </figure>
              <p>use mouse to look around</p>
            </div>
            <div class="col-6">
              <figure>
                <img :src="`${publicPath}/img/content/flip-device.svg`" alt="use widescreen" title="use widescreen" />
              </figure>
              <p>use widescreen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Api } from "@/api";
//import axios from "axios";
//import { EventBus } from "@/bus/event-bus.js";
import MouseEvents from "@/utils/mouseevents";

export default {
  name: "home",
  components: { },
  data() {
    return {
      contentLoaded: "",
      publicPath: Api.PUBLIC_FOLDER,
    };
  },
  computed: {
    ...mapGetters(["gSize", "gTourActive", "gLanguage"]),
    frameUrl() {
      return Api.VIRTUAL_URL + (this.gLanguage=="en"?"?" + Api.EN_LANGUAGE_PARAM:"");
    } ,
    styleIframeHeight() {
      return "height: " + (this.gSize.screen.height - this.gSize.header.height) + "px;";
    },
    tourActive() {
      return this.gTourActive;
    }  
  },
  props: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    contentElementClicked(event) {
      MouseEvents.contentElementClicked(event);
    },
    startTour(language, ev) {
      this.$store.dispatch('setLanguage', language);
      this.$store.dispatch("setTourActive", true);
      ev.preventDefault();
    }  
  },
};
</script>
